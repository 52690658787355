jQuery(window).load(function () {

	/* Check device */
	if (isMobile.phone == true) {
		$('html').addClass('mobile no-tablet no-desktop');
	} else if (isMobile.tablet == true) {
		$('html').addClass('tablet no-mobile no-desktop');
	} else {
		$('html').addClass('desktop no-mobile no-tablet');
	}

	$(document).on('change', '.js-otm-change', function(e) {
		var kenmerk = $(this).find(':selected').attr('data-kenmerk');
		var otmContainer = $('.js-iframe-container');
		var brand = $(this).find(':selected').attr('data-brand');

		otmContainer.html('');

		$.ajax({
			type: "post",
			dataType: "json",
			url: ajaxObject.url,
			data: {
				action: 'otmChange',
				kenmerk: kenmerk,
				brand: brand,
			},
			beforeSend: function() {
				otmContainer.addClass('is-loading');
			},
			error: function(request, textstatus, errorThrown) {
				console.log('Call failed');
				console.log(request);
				console.log(textstatus);
				console.log(errorThrown); //test
			},
			success: function(response){
				if(otmContainer.length) {
					otmContainer.removeClass('is-loading');
					otmContainer.find('#taxatie_iframe_container').remove();
					otmContainer.append(response.data);
					iFrameResize();
				}
			}
		});

		e.preventDefault();
	});


	/* Give elements an equal height */
	equalHeight($('#model-gegevens .columns'));

	if (jQuery.browser.mobile) {
		$('html').addClass('mobile');
	} else {
		$('html').addClass('no-mobile');
	}

	/* Slick slider */
	$('#galerij-slider').slick({
		rows: 2,
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 4,
		arrows: true,
		prevArrow: '<a title="Vorige slide" class="galerij__slider__btn galerij__slider__btn--vorige slider__btn hide-for-touch"><i><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 55" xml:space="preserve"><polygon points="27.2,0 30,2.9 5.7,27.5 30,52.1 27.2,55 0,27.5"/></svg></i></a>',
		nextArrow: '<a title="Volgende slide" class="galerij__slider__btn galerij__slider__btn--volgende slider__btn hide-for-touch"><i><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 55" xml:space="preserve"><polygon points="2.8,55 0,52.1 24.3,27.5 0,2.9 2.8,0 30,27.5"/></svg></i></a>',
		dots: true,
		dotsClass: 'galerij__slider__dots slider__dots',
		responsive: [{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3
			}
		}, {
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			}
		}, {
			breakpoint: 400,
			settings: {
				arrows: false,
				rows: 2,
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}]
	});

	$('#banner .slider ul').slick({
		slide: 'li',
		fade: false,
		swipe: true,
		touchMove: true,
		swipeToSlide: true,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 4000,
		speed: 800,
		focusOnSelect: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		prevArrow: '<span class="slider-nav prev hide-for-touch"><i><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 56" enable-background="new 0 0 30 56" xml:space="preserve"><path d="M5.4,28L30,2.7L27.3,0L0,28l27.3,28l2.7-2.7L5.4,28z"/></svg></i></div>',
		nextArrow: '<span class="slider-nav next hide-for-touch"><i><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 56" enable-background="new 0 0 30 56" xml:space="preserve"><path d="M0,53.3L2.7,56L30,28L2.7,0L0,2.7L24.6,28L0,53.3z"/></svg></i></div>',

	});

	$('#lease-galerij .slider ul').slick({
		slidesToShow: 8,
		slidesToScroll: 1,
		dots: false,
		slide: 'li',
		focusOnSelect: false,
		appendArrows: '#lease-galerij .slider',
		prevArrow: '<span class="slider-nav prev hide-for-touch"><i><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 56" enable-background="new 0 0 30 56" xml:space="preserve"><path d="M5.4,28L30,2.7L27.3,0L0,28l27.3,28l2.7-2.7L5.4,28z"/></svg></i></div>',
		nextArrow: '<span class="slider-nav next hide-for-touch"><i><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 56" enable-background="new 0 0 30 56" xml:space="preserve"><path d="M0,53.3L2.7,56L30,28L2.7,0L0,2.7L24.6,28L0,53.3z"/></svg></i></div>',
		responsive: [{
			breakpoint: 1024,
			settings: {
				slidesToShow: 6
			}
		},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 4
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2
				}
			}]
	});

	$('.reviews [data-slick-slider]').slick({
		slide: 'li',
		infinite: false,
		slidesToShow: 1,
		swipeToSlide: true,
		slidesToScroll: 1,
		adaptiveHeight: true,
		prevArrow: '<a title="Vorige" class="slider-nav prev"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
		nextArrow: '<a title="Volgende" class="slider-nav next"><i class="fa fa-angle-right" aria-hidden="true"></i></a>'
	});


	$('#uitvoeringen-slider .model ul.slider').slick({
		slide: 'li',
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		adaptiveHeight: true,
		asNavFor: '#uitvoeringen-slider .nav ul.slider'
	});
	$('#uitvoeringen-slider .nav ul.slider').slick({
		slide: 'li',
		slidesToShow: 4,
		slidesToScroll: 0,
		focusOnSelect: true,
		vertical: true,
		verticalSwiping: true,
		asNavFor: '#uitvoeringen-slider .model ul.slider'
	});


	$('.galerij__slider .slick-list').each(function () {
		var $container = $(this);
		var $imageLink = $container.find('a:not(.otm)');
		var items = [];
		$imageLink.each(function () {
			var $item = $(this);
			var type = 'image';
			if (this.href.indexOf('watch?v=') != -1 || this.href.indexOf('youtube.com') != -1 || this.href.indexOf('player.vimeo.com') != -1) {
				type = 'iframe';
			}
			var magItem = {
				src: $item.attr('href'),
				type: type
			};
			magItem.title = $item.data('title');
			items.push(magItem);
		});

		$imageLink.magnificPopup({
			items: items,
			type: 'image',
			closeBtnInside: false,
			tClose: 'Sluiten (Esc)',
			tLoading: 'Laden...',
			swipe: true,
			gallery: {
				enabled: true,
				tPrev: 'Vorige (Linker pijl toets)',
				tNext: 'Volgende (Rechter pijl toets)',
				tCounter: '%curr% van %total%'
			},
			callbacks: {
				beforeOpen: function () {
					var index = $imageLink.index(this.st.el);
					if (-1 !== index) {
						this.goTo(index);
					}
				}
			}
		});
	});

	$('#gegevens .otm-slider-container ul.slider').slick({
		slide: 'li',
		slidesToShow: 1,
		slidesToScroll: 1,
		swipe: false,
		arrows: false,
		adaptiveHeight: true,
		asNavFor: '#gegevens .otm-slider-nav ul.slider'
	});
	$('#gegevens .otm-slider-nav ul.slider').slick({
		slide: 'li',
		slidesToShow: 4,
		slidesToScroll: 0,
		focusOnSelect: true,
		vertical: true,
		verticalSwiping: true,
		asNavFor: '#gegevens .otm-slider-container ul.slider'
	});


	$('#model-galerij .showcase ul').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		prevArrow: '<a title="Vorige slide" class="galerij__slider__btn galerij__slider__btn--vorige"><i><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 55" xml:space="preserve"><polygon points="27.2,0 30,2.9 5.7,27.5 30,52.1 27.2,55 0,27.5"/></svg></i></a>',
		nextArrow: '<a title="Volgende slide" class="galerij__slider__btn galerij__slider__btn--volgende"><i><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 55" xml:space="preserve"><polygon points="2.8,55 0,52.1 24.3,27.5 0,2.9 2.8,0 30,27.5"/></svg></i></a>',
		fade: false,
		swipe: true,
		slide: 'li',
		infinite: false,
		asNavFor: '#model-galerij .thumbs ul',
		touchMove: true
	});

	$('#model-galerij .thumbs ul').slick({
		slidesToShow: 8,
		slidesToScroll: 1,
		asNavFor: '#model-galerij .showcase ul',
		dots: false,
		arrows: false,
		slide: 'li',
		infinite: false,
		focusOnSelect: true,
		touchMove: false
	});

	$('.touch .occasions.detail .model-afb .slides').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
		slide: 'li',
		touchMove: false
	});

	$('.occasions.detail #carousel-nav .slides').slick({
		slide: 'li',
		slidesToShow: 8,
		slidesToScroll: 1,
		swipe: false,
		touchMove: false,
		infinite: false,
		focusOnSelect: true,
		prevArrow: '<span class="slider-nav prev hide-for-touch"><i><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 56" enable-background="new 0 0 30 56" xml:space="preserve"><path d="M5.4,28L30,2.7L27.3,0L0,28l27.3,28l2.7-2.7L5.4,28z"/></svg></i></div>',
		nextArrow: '<span class="slider-nav next hide-for-touch"><i><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 56" enable-background="new 0 0 30 56" xml:space="preserve"><path d="M0,53.3L2.7,56L30,28L2.7,0L0,2.7L24.6,28L0,53.3z"/></svg></i></div>',
		responsive: [{
			breakpoint: 1024,
			settings: {
				slidesToShow: 6
			}
		},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3
				}
			}
		]
	});


	// Slider large-block-grid-
	$('.slider.large-block-grid-4').slick({
		slide: 'li',
		fade: false,
		swipe: true,
		touchMove: true,
		infinite: false,
		slidesToShow: 4,
		slidesToScroll: 4,
		arrows: true,
		prevArrow: '<a title="Vorige" class="slider-nav prev"><i class="fa fa-angle-left"></i></a>',
		nextArrow: '<a title="Volgende" class="slider-nav next"><i class="fa fa-angle-right"></i></a>',
		responsive: [{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3
			}
		}, {
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			}
		}, {
			breakpoint: 640,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}]
	});
	$('.slider.large-block-grid-3').slick({
		slide: 'li',
		fade: false,
		swipe: true,
		touchMove: true,
		infinite: false,
		slidesToShow: 3,
		slidesToScroll: 3,
		arrows: true,
		prevArrow: '<a title="Vorige" class="slider-nav prev"><i class="fa fa-angle-left"></i></a>',
		nextArrow: '<a title="Volgende" class="slider-nav next"><i class="fa fa-angle-right"></i></a>',
		responsive: [{
			breakpoint: 1024,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			}
		}, {
			breakpoint: 640,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}]
	});


	$('.employees .employees__slider').slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		dots: true,
		arrows: true,
		prevArrow: '<a title="Vorige" class="slider-nav prev"><i class="fa fa-angle-left"></i></a>',
		nextArrow: '<a title="Volgende" class="slider-nav next"><i class="fa fa-angle-right"></i></a>',
		infinite: true,
		cssEase: 'linear',
		responsive: [{
			breakpoint: 960,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			}
		}, {
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			}
		}]
	});

	function onzeModellenSlider() {
		if ($(window).width() < 769) {
			$('#onze-modellen .large-block-grid-3').slick({
				slide: 'li',
				fade: false,
				swipe: true,
				touchMove: true,
				infinite: false,
				slidesToShow: 3,
				slidesToScroll: 3,
				arrows: true,
				prevArrow: '<a title="Vorige" class="slider-nav prev"><i class="fa fa-angle-left"></i></a>',
				nextArrow: '<a title="Volgende" class="slider-nav next"><i class="fa fa-angle-right"></i></a>',
				responsive: [{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				}, {
					breakpoint: 640,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}]
			});
		} else {
			$('#onze-modellen .large-block-grid-3').slick('unslick');
		}
	}

	onzeModellenSlider();

	$('.slick-slider').on('swipe', function (event, slick, direction) {
		$(this).find('.slick-list').addClass('swiped');
	});


	function setSliderTouchImage() {
		setTimeout(function () {
			$('.slick-track').each(function () {
				if ($($(this)).children('li[aria-hidden="true"]').length == 0) {
					$(this).parent().removeClass('draggable');
				}
			});
		}, 1000);
	}

	setSliderTouchImage();
	$(window).resize(function () {
		setSliderTouchImage();
		onzeModellenSlider();
	});

	setTimeout(function () {
		if (!$('iframe').attr("data-iframe-iframeresizer")) {
			$(this).iFrameResize();
		}
	}, 3000);

	/* Scroll to ID */
	$('[data-scroll]').click(function (event) {
		if ($(this).is('[data-scroll=page]')) {
			var header = $('#topbar:visible').outerHeight() + $('#header:visible').outerHeight();
			event.preventDefault();
			var hash = $(this).attr('href');
			var top = hash === "#" ? 0 : $(hash).offset().top - header;
			$('html, body').stop().animate({
				scrollTop: top
			}, 1200);
		}
		if ($(this).is('[data-scroll=top]')) {
			event.preventDefault();
			$('html, body').stop().animate({
				scrollTop: 0
			}, 1200);
		}
	});

	/* Scroll based on location hash */
	if(document.location.hash){
		var header = $('#topbar:visible').outerHeight() + $('#header:visible').outerHeight();
		var hash   = document.location.hash;
		var offset = hash === "#" ? 0 : $(hash).offset().top - header;

		$('html, body').scrollTop(offset);
	}
});


jQuery(function ($) {

	$('#nav').doubleTapToGo();

	/* Open a tab when data-opentab is clicked */
	$('[data-opentab]').on('click', function (e) {
		e.stopPropagation();
		e.preventDefault();

		var tabToOpen = $(this).data('opentab');

		if ($('[data-tabtarget="' + tabToOpen + '"]').length > 0) {
			toggleTabs(tabToOpen);
		}
	})

	/* Merken select */
	$('#topbar .select-wrapper h6.default').click(function (event) {
		$('#topbar .select-wrapper').toggleClass('close open').find('ul').slideToggle();
		$('html').one('click', function () {
			$('#topbar .select-wrapper').toggleClass('close open').find('ul').slideUp();
		});
		event.stopPropagation();
	});

	/* Filter brochures */
	$('[data-brochures]').click(function (event) {
		$('[data-brochures]').removeClass('active');
		$(this).addClass('active');
		event.preventDefault();
		var soort = $(this).data('brochures');

		$('#brochures .items .item').fadeOut(500).promise().done(function () {
			$('#brochures .items .item.' + soort).fadeIn(500);
		});
	});

	/* Filter acties */
	$('[data-acties]').click(function (event) {
		$('[data-acties]').removeClass('active');
		$(this).addClass('active');
		event.preventDefault();
		var soort = $(this).data('acties');

		$('#acties-detail ul li').fadeOut(500).promise().done(function () {
			$('#acties-detail ul li.' + soort).fadeIn(500);
		});
	});

	/* Filter modellen */
	$('[data-modellen]').click(function (event) {
		$('[data-modellen]').removeClass('active');
		$(this).addClass('active');
		event.preventDefault();
		var soort = $(this).data('modellen');

		$('#modellen-detail .modellen .item').fadeOut(500).promise().done(function () {
			$('#modellen-detail .modellen .item.' + soort).fadeIn(500);
		});
	});

	//$('[data-modellen]').first().click();


	/* Responsive videos */
	$(".fluid-video").fitVids();

	/* One page navigation */
	if ($("#one-page-nav").length) {
		$(window).scroll(function () {
			setNavActiveState();
		});
	}

	/* Equal heights */
	function equalHeight(group) {
		var height = 0;
		group.each(function () {
			element = $(this).outerHeight();
			if (element > height) {
				height = element;
			}
		});
		group.height(height);
	};


	/* Menu trigger */
	$('.trigger-menu').click(function (event) {
		event.preventDefault();
		$('#nav').slideToggle();
	});


	/* Magnific popup */
	$('.lightbox, .lightbox a, #carousel-album a').magnificPopup({
		type: 'image',
		mainClass: 'mfp-with-zoom',
		tClose: 'Sluiten (Esc)',
		tLoading: 'Laden...',
		zoom: {
			enabled: true,
			duration: 300,
			easing: 'ease-in-out',
			opener: function (openerElement) {
				return openerElement.is('img') ? openerElement : openerElement.find('img');
			}
		},
		gallery: {
			enabled: true,
			tPrev: 'Vorige (Linker pijl toets)',
			tNext: 'Volgende (Rechter pijl toets)',
			tCounter: '%curr% van %total%'
		}
	});

	//eigen popup for page galleries (custom made)
	$('#page .content').find('a[href$=".jpg"], a[href$=".png"]').magnificPopup({
		type: 'image',
		mainClass: 'mfp-with-zoom',
		tClose: 'Sluiten (Esc)',
		tLoading: 'Laden...',

		gallery: { enabled: true }
	});

	$('.movie-link').magnificPopup({
		type: 'iframe',
		mainClass: 'mfp-with-zoom',
		tClose: 'Sluiten (Esc)',
		tLoading: 'Laden...'
	});

	$('.ajax-popup-link').magnificPopup({
		type: 'iframe',
		callbacks: {
			open: function () {
				$('iframe').iFrameResize();
			}
		}
	});

	$('.video_popup').magnificPopup({
		disableOn: 700,
		type: 'iframe',
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,

		fixedContentPos: false
	});


	/* Form validation */
	$('form').each(function () {
		$(this).validate({
			errorPlacement: function (error, element) {
				if (element.is(':radio') || element.is(':checkbox')) {
					error.remove();
				} else if (element.attr('name') == 'cv') {
					error.insertAfter('.cv');
				} else if (element.attr('name') == 'brief') {
					error.insertAfter('.brief');
				} else {
					$(element).after(error);
				}
			}
		});
	});

	/* form accordion */
	$("form.accordion > fieldset:not(:last-child) button").on('click', function () {
		if ($("form.accordion").valid()) {
			$(this).closest("fieldset").toggleClass('active done').find('section').slideToggle();
			$(this).closest("fieldset").next("fieldset").addClass('active').find('section').slideToggle();

			return false;
		}
	});

	$("form.accordion > fieldset > header").on('click', function () {
		if ($(this).parent('fieldset').hasClass('done')) {
			$("form.accordion > fieldset.active").find('section').slideToggle();
			$(this).closest("fieldset").toggleClass('active done').find('section').slideToggle();
			$("form.accordion > fieldset.active").nextAll('fieldset').removeClass('done active');
		}
	});


	$(function () {
		// form validate
		$("form#service-offerte").validate({
			errorPlacement: function (error, element) {
				error.insertAfter(element);
			},
			rules: {
				telefoonnummer: {
					required: true,
					phoneNL: true
				},
				'service[]': {
					required: true
				}
			}
		});
	});




	/* Change button text of upload file to selected filename */
	$(document).on('change', '.ginput_container_fileupload input[type="file"]', function (event) {
		$(this).closest('li').find('.gfield_label').first().text($(this)[0].files[0].name);
	});



	//highlightig autocomplete
	$.ui.autocomplete.prototype._renderItem = function (ul, item) {
		var term = this.term.split(' ').join('|');
		var re = new RegExp("(" + term + ")", "gi");
		var t = item.label.replace(re, "<b>$1</b>");
		return $("<li></li>")
			.data("item.autocomplete", item)
			.append("<a>" + t + "</a>")
			.appendTo(ul);
	};

	//trefwoord autocomplete
	$("input#zoeken").autocomplete({
		source: dtcmedia_vm.ajaxurl + "?action=site_search_autocomplete",
		minLength: 2,
		messages: {
			noResults: '',
			results: function () {
			}
		},
		open: function () {
			$(".ui-autocomplete:visible").css({top: "+=15"});
		},
		select: function (event, ui) {
			$(event.target).val(ui.item.value);
			this.form.submit()
			return false;
		}
	});

	$('form.zoeken').submit(function (e) {
		if ($(this).find('input[type=search]').val().length < 3) {
			e.preventDefault();
			$(this).addClass('error');
			$('form.zoeken label.errorTekst').fadeIn().delay(3000).fadeOut();
		}
	});


	/* Submenu's */
	var delaySub;
	$('.submenu').parent('li').addClass('has-submenu');
	$('.submenu', '.has-submenu.modellen').addClass('modellen');
	$('.has-submenu, .submenu').hover(function () {
		clearTimeout(delaySub);
		$('.submenu').removeClass('active');
		$('.submenu', $(this)).addClass('active');
	}, function () {
		clearTimeout(delaySub);
		delaySub = setTimeout(function () {
			$('.submenu').removeClass('active');
		}, 1000);
	});


	/* Tabs's */
	$('.tabs').each(function () {
		// For each set of tabs, we want to keep track of
		// which tab is active and it's associated content
		var $active, $content, $links = $(this).find('a');

		// If the location.hash matches one of the links, use that as the active tab.
		// If no match is found, use the first link as the initial active tab.
		$active = $($links.filter('[href="' + location.hash + '"]')[0] || $links[0]);
		$active.addClass('active');

		$content = $($active[0].hash);

		// Hide the remaining content
		$links.not($active).each(function () {
			$(this.hash).addClass('hidden');
		});

		// Bind the click event handler
		$(this).on('click', 'a', function (e) {
			// Make the old tab inactive.
			$active.removeClass('active');
			$content.addClass('hidden');

			// Update the variables with the new link and content
			$active = $(this);
			$content = $(this.hash);

			// Make the tab active.
			$active.addClass('active');
			$content.removeClass('hidden');

			// Prevent the anchor's default click action
			e.preventDefault();
		});
	});

	//Rent formulier start-einddatum dynamisch min-max
	$('.js-date-start input, .js-date-end input, .js-date-nopast input').datepicker({'minDate': 0});

	$(document).on('change', '.js-date-start input', function () {
		$start = $(this);
		$end = $('.js-date-end input');
		$end.datepicker('option', 'minDate', $start.val());
	});


	// Static rating in stars
	$(".reviews.slider .sterren.statisch").jRating({
		bigStarsPath: wp_urls.template + '/static/img/reviews/stars-bg.png',
		isDisabled: true,
		length: 5,
		rateMax: 5
	});

	$(".reviews.schrijven .sterren.statisch").jRating({
		bigStarsPath: wp_urls.template + '/static/img/reviews/stars-bg.png',
		isDisabled: true,
		length: 5,
		rateMax: 5
	});

	// Dynamic rating in stars
	function dynamicStars() {
		var $ster = $('form .beoordeling.geven');
		$ster.append('<div class="sterren"></div>');
		$ster.find('.sterren').jRating({
			bigStarsPath: wp_urls.template + '/static/img/reviews/stars-bg.png',
			showRateInfo: false,
			type: 'big',
			length: 5,
			rateMax: 5,
			step: true,
			canRateAgain: true,
			nbRates: 99,
			phpPath: false,
			lengthInteger: 5,
			onClick: function (element, rate) {
				$ster.find('input').val(rate);
			}
		});

		function setStars() {
			$ster.find('.sterren .jRatingAverage').css({width: $('.beoordeling.geven .ginput_container input').val() * 20 + "%"});
		}

		$('.beoordeling').on("mouseleave", function () {
			setStars();
		});
		setStars();
	}

	dynamicStars();

	elemToggle();

	new VanillaFilter();

	let $triggers = $('[data-vanillatrigger]');

	$triggers.on('click touchstart', function(event) {
		event.preventDefault();
		let $trigger = $(this);

		if($trigger.hasClass('is-active')) {
			$trigger.removeClass('is-active');
		} else {
			$trigger.addClass('is-active');
		}
	});

});


function occasionmodule_after_merk_refresh() {
}
function financieringsmodule_select_refresh(field) {
}

function toggleTabs(tabToOpen) {
	var slides = $('.offerte-lease__tabs--tabs ul[data-slider] li');

	$('[data-opentab], [data-tabtarget]').removeClass('active');
	$('[data-tabtarget="' + tabToOpen + '"]').addClass('active');
	$('[data-opentab="' + tabToOpen + '"]').addClass('active');

	var activeSlide = $('.offerte-lease__tabs--tabs ul[data-slider] li a.active').parent();
	var prevSlide = (activeSlide.prev().length > 0) ? activeSlide.prev() : slides.last();
	var nextSlide = (activeSlide.next().length > 0) ? activeSlide.next() : slides.first();

	$('.offerte-lease__tabs--content .prev a').html(prevSlide.find('h4').text()).data('opentab', prevSlide.find('a').data('opentab'));
	$('.offerte-lease__tabs--content .next a').html(nextSlide.find('h4').text()).data('opentab', nextSlide.find('a').data('opentab'));
}

document.addEventListener('DOMContentLoaded', function () {
	if (document.querySelectorAll(".openingAmsterdam").length > 0) {
		dictanceToNearestLocation();
		fillMap();
		scrollToElement();
		iFrameResize({
			resizedCallback: function (e) {
				e.iframe.parentNode.style.height = e.height + "px";
			}
		}, "iframe");
	}
});

function dictanceToNearestLocation() {
	var distance = false;
	var link;

	var interval = setInterval(function () {
		if (distance !== false) {
			showDistanceTop(distance, link);
			clearInterval(interval);
		}
	}, 100);

	distanceToLocation(['Donauweg 11, Amsterdam', 'Klokkenbergweg 3, Amsterdam'], function (response) {
		if (typeof response === "object") {
			var distances = response.rows[0].elements;
			if (distances[0].distance.value < distances[1].distance.value) {
				distance = Math.round(distances[0].distance.value / 1000);
				link = "https://www.google.nl/maps/dir//Autobedrijf+Nieuwendijk+Renault+%26+Dacia+Amsterdam+West,+Donauweg+11,+1043+AJ+Amsterdam";
			} else {
				distance = Math.round(distances[1].distance.value / 1000);
				link = "https://www.google.nl/maps/dir//Autobedrijf+Nieuwendijk+Renault+%26+Dacia+Amsterdam+Zuid-Oost,+Klokkenbergweg+3,+1101+AK+Amsterdam";
			}
		} else {
			distance = true;
			link = "https://www.google.nl/maps/dir//Autobedrijf+Nieuwendijk+Renault+%26+Dacia+Amsterdam+West,+Donauweg+11,+1043+AJ+Amsterdam";
		}
	});
}

function showDistanceTop(distance, link) {
	if (distance !== true) {
		distance = distance.toString().split("");
		var span = '';
		for (var i = 0; i < distance.length; i++) {
			span += "<span>" + distance[i] + "</span>";
		}
		document.querySelector('.location h2').innerHTML = "U vindt al een vestiging in Amsterdam op <span><i class='marker'></i>" + span + "</span> kilometer";
	}
	document.querySelector('.location a').setAttribute("href", link);
}

function distanceToLocation(location, callback) {

	var distances;
	getLocation(function (e) {
		calulateDistance(e, location, function (e) {
			distances = e;
		});
	});

	var steps = 0;
	var interval = setInterval(function () {
		if (typeof distances === "object") {
			clearInterval(interval);
			callback(distances);
		}

		if (steps >= 100) {
			clearInterval(interval);
			callback();
		}
		steps++;
	}, 100);
}

function calulateDistance(from, to, callback) {
	var args = {
		origins: [new google.maps.LatLng(from.latitude, from.longitude)],
		destinations: [],
		travelMode: 'DRIVING'
	};

	for (var i = 0; i < to.length; i++) {
		args.destinations.push(to[i]);
	}

	var service = new google.maps.DistanceMatrixService();
	service.getDistanceMatrix(args, callback);
}

function getLocation(callback) {
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(function (e) {
			callback(e.coords);
		});
	}
}

function fillMap() {
	var mapEl = document.querySelector(".googleMaps .map");
	var map = new google.maps.Map(mapEl, {
		zoomControl: false,
		mapTypeControl: false,
		scaleControl: false,
		streetViewControl: false,
		rotateControl: false,
		fullscreenControl: false
	});

	var bounds = new google.maps.LatLngBounds();

	getLocation(function (e) {
		bounds.extend({
			lat: e.latitude,
			lng: e.longitude
		});

		map.fitBounds(bounds);
	});

	distanceToLocation(['Donauweg 11, Amsterdam', 'Klokkenbergweg 3, Amsterdam'], function (response) {
		var marker2 = {
			position: {
				lat: 52.3940947,
				lng: 4.8386602
			},
			icon: mapEl.getAttribute('data-base-url') + "/static/img/opening-amsterdam/gmaps-marker.png?marker1",
			map: map
		};

		var marker3 = {
			position: {
				lat: 52.303420,
				lng: 4.935431
			},
			icon: mapEl.getAttribute('data-base-url') + "/static/img/opening-amsterdam/gmaps-marker.png?marker2",
			map: map
		};

		if (typeof response === "object") {
			var distances = response.rows[0].elements;

			marker2.title = Math.round(distances[0].distance.value / 1000) + " km.";
			marker3.title = Math.round(distances[1].distance.value / 1000) + " km.";
		}

		marker2 = new google.maps.Marker(marker2);
		marker3 = new google.maps.Marker(marker3);

		marker2.addListener('click', function () {
			document.querySelector(".googleMaps article:nth-of-type(1)").classList.add("active");
			document.querySelector(".googleMaps article:nth-of-type(2)").classList.remove("active");
			document.querySelector(".googleMaps article:nth-of-type(1)").scrollIntoView({behavior: "smooth"});
		});
		marker3.addListener('click', function () {
			document.querySelector(".googleMaps article:nth-of-type(2)").classList.add("active");
			document.querySelector(".googleMaps article:nth-of-type(1)").classList.remove("active");
			document.querySelector(".googleMaps article:nth-of-type(2)").scrollIntoView({behavior: "smooth"});
		});
		map.addListener('click', function () {
			document.querySelector(".googleMaps article:nth-of-type(1)").classList.remove("active");
			document.querySelector(".googleMaps article:nth-of-type(2)").classList.remove("active");
		});

		bounds.extend(marker2.position);
		bounds.extend(marker3.position);
		bounds.extend({lat: 52.40, lng: 4.8386602});
		map.fitBounds(bounds);
	});
}

function scrollToElement() {
	var a = document.querySelectorAll("a[href^='#']:not([href='#'])");

	for (var i = 0; i < a.length; i++) {
		a[i].addEventListener('click', function (e) {
			e.preventDefault();
			var target = this.getAttribute('href').replace("#", "");
			document.querySelector('#' + target).scrollIntoView({behavior: "smooth"});
		})
	}
}



//Inruilweken filterSlider
document.sliding = false;
document.timelineSlided = false;
document.thumbsSlided = false;
document.addEventListener('DOMContentLoaded', function () {

	if (document.querySelectorAll(".modelSlider").length > 0) {
		filters();
		modelSlider();
		thumbsSlider();
	}
});

function filters() {
	var filters = document.querySelectorAll("[data-filter]");
	for (var i = 0; i < filters.length; i++) {
		var values = filters[i].querySelectorAll('[data-value]');

		for (var b = 0; b < values.length; b++) {
			if ('ontouchstart' in document.documentElement) {
				values[b].addEventListener("touchend", function () {
					if (!document.thumbsSlided) {
						filter.call(this);
					}
				});
			} else {
				values[b].addEventListener("mousedown", function () {
					filter.call(this);
				});
			}
		}
	}
}

function filter() {
	if (!this.classList.contains("active")) {
		var filter = this.parentNode.getAttribute("data-filter");
		var values = this.parentNode.querySelectorAll('[data-value]');
		var value = this.getAttribute('data-value');
		for (var i = 0; i < values.length; i++) {
			values[i].classList.remove("active");
		}
		this.classList.add("active");

		if (this.parentNode.getAttribute("data-type") === "slider") {
			modelSliderTo(this.getAttribute('data-value'));
		} else {
			var items = document.querySelectorAll("[data-" + filter + "]");
			if (value !== "all") {
				for (i = 0; i < items.length; i++) {
					items[i].style.marginLeft = "0px";
					items[i].classList.remove("active");
					items[i].classList.add("hidden");
					document.querySelector('[data-' + items[i].parentNode.getAttribute("data-filter") + '="' + items[i].getAttribute('data-value') + '"]').classList.add('hidden');
				}

				var selectedItems = document.querySelectorAll("[data-" + filter + "*='" + value + "']");
				for (i = 0; i < selectedItems.length; i++) {
					selectedItems[i].style.marginLeft = "0px";
					selectedItems[i].classList.remove('hidden');
					document.querySelector('[data-' + selectedItems[i].parentNode.getAttribute("data-filter") + '="' + selectedItems[i].getAttribute('data-value') + '"]').classList.remove('hidden');
					document.querySelector('[data-' + selectedItems[i].parentNode.getAttribute("data-filter") + '="' + selectedItems[i].getAttribute('data-value') + '"]').style.marginLeft = "0px";

					if (i === 0) {
						selectedItems[i].classList.add("active");
						modelSliderTo(selectedItems[i].getAttribute('data-value'));
					} else {
						selectedItems[i].classList.remove("active");
					}
				}
			} else {
				for (i = 0; i < items.length; i++) {
					items[i].style.marginLeft = "0px";
					items[i].classList.remove("hidden");
					document.querySelector('[data-' + items[i].parentNode.getAttribute("data-filter") + '="' + items[i].getAttribute('data-value') + '"]').classList.remove('hidden');
					document.querySelector('[data-' + items[i].parentNode.getAttribute("data-filter") + '="' + items[i].getAttribute('data-value') + '"]').style.marginLeft = "0px";

					if (i === 0) {
						items[i].classList.add("active");
						modelSliderTo(items[i].getAttribute('data-value'));
					} else {
						items[i].classList.remove("active");
					}
				}
			}
		}
	}
}

function thumbsSlider() {
	var slider = document.querySelector(".modelSlider .modelSlider__filterModels");
	slider.setAttribute("data-current-slide", 0);

	var sliding = false;
	var animating = false;
	var pageOffset = 0;
	var pageX = 0;
	var pageY = 0;

	slider.addEventListener('touchstart', function (e) {
		sliding = true;
		animating = false;
		pageX = e.pageX;
		pageY = e.pageY;
		pageOffset = window.pageYOffset;
		document.thumbsSlided = false;

		if (typeof TouchEvent !== "undefined" && e instanceof TouchEvent) {
			pageX = e.touches[0].pageX;
			pageY = e.touches[0].pageY;
		}

	});

	document.addEventListener('touchmove', function (e) {
		if (sliding && !document.sliding) {
			window.requestAnimationFrame(function () {
				var diffX = pageX - e.pageX;
				var diffY = pageY - e.pageY;

				if (typeof TouchEvent !== "undefined" && e instanceof TouchEvent) {
					diffX = pageX - e.touches[0].pageX;
					diffY = pageY - e.touches[0].pageY;
				}

				if (Math.sqrt(Math.pow(diffX, 2)) > Math.sqrt(Math.pow(diffY, 2))) {
					animating = true;
				}

				if (animating && Math.sqrt(Math.pow(diffX, 2)) > 100) {
					sliding = false;
					animating = false;
					document.thumbsSlided = true;
					if (diffX < 0) {
						thumbsSliderSlide("prev");
					} else {
						thumbsSliderSlide("next");
					}
				}
			});
		}
	});


	if ('ontouchstart' in document.documentElement) {
		document.addEventListener('touchend', function () {
			sliding = false;
		});
		slider.querySelector('.prev').addEventListener("touchstart", function () {
			thumbsSliderSlide("prev");
		});
		slider.querySelector('.next').addEventListener("touchstart", function () {
			thumbsSliderSlide("next");
		});
	} else {
		slider.querySelector('.prev').addEventListener("mousedown", function () {
			thumbsSliderSlide("prev");
		});
		slider.querySelector('.next').addEventListener("mousedown", function () {
			thumbsSliderSlide("next");
		});
	}
}

function thumbsSliderSlide(dir) {
	var slider = document.querySelector(".modelSlider .modelSlider__filterModels");
	var slides = slider.querySelectorAll(".modelSlider__filterModelsSlide:not(.hidden)");
	var currentSlide = parseInt(slider.getAttribute("data-current-slide"));
	var width = slides[0].getBoundingClientRect().width;
	var slidesInView = Math.floor(slider.getBoundingClientRect().width / width);

	if (dir === "prev") {
		console.log(slides.length, slidesInView, currentSlide);
		if (currentSlide - slidesInView < 0) {
			currentSlide = Math.floor((slides.length - 1) / slidesInView) * slidesInView;
		} else {
			currentSlide = currentSlide - slidesInView;
		}
	} else if (dir === "next") {
		if (currentSlide + slidesInView > slides.length - 1) {
			currentSlide = 0;
		} else {
			currentSlide = currentSlide + slidesInView;
		}
	}

	slider.setAttribute("data-current-slide", currentSlide);
	slides[0].style.marginLeft = (slides[0].getBoundingClientRect().width * -currentSlide) + "px";
}

function modelSlider() {
	var slider = document.querySelector(".modelSlider__slider");
	slider.setAttribute("data-current-slide", 0);

	var sliding = false;
	var animating = false;
	var pageOffset = 0;
	var pageX = 0;
	var pageY = 0;

	slider.addEventListener('touchstart', function (e) {
		sliding = true;
		animating = false;
		pageX = e.pageX;
		pageY = e.pageY;
		pageOffset = window.pageYOffset;

		if (typeof TouchEvent !== "undefined" && e instanceof TouchEvent) {
			pageX = e.touches[0].pageX;
			pageY = e.touches[0].pageY;
		}

	});

	document.addEventListener('touchmove', function (e) {
		if (sliding && !document.sliding) {
			window.requestAnimationFrame(function () {
				var diffX = pageX - e.pageX;
				var diffY = pageY - e.pageY;

				if (typeof TouchEvent !== "undefined" && e instanceof TouchEvent) {
					diffX = pageX - e.touches[0].pageX;
					diffY = pageY - e.touches[0].pageY;
				}

				if (Math.sqrt(Math.pow(diffX, 2)) > Math.sqrt(Math.pow(diffY, 2))) {
					animating = true;
				}

				if (animating && Math.sqrt(Math.pow(diffX, 2)) > 100) {
					sliding = false;
					animating = false;
					if (diffX < 0) {
						modelSliderTo("prev");
					} else {
						modelSliderTo("next");
					}
				}
			});
		}
	});

	if ('ontouchstart' in document.documentElement) {
		document.addEventListener('touchend', function () {
			sliding = false;
		});
		slider.querySelector('.prev').addEventListener("touchstart", function () {
			modelSliderTo("prev");
		});
		slider.querySelector('.next').addEventListener("touchstart", function () {
			modelSliderTo("next");
		});
	} else {
		slider.querySelector('.prev').addEventListener("mousedown", function () {
			modelSliderTo("prev");
		});
		slider.querySelector('.next').addEventListener("mousedown", function () {
			modelSliderTo("next");
		});
	}
}

function modelSliderTo(slide) {
	var slider = document.querySelector(".modelSlider__slider");
	var slides = slider.querySelectorAll(".modelSlider__slider .modelSlider__slidesContent:not(.hidden)");
	var nav = document.querySelectorAll(".modelSlider__filterModels .modelSlider__filterModelsSlide");
	var currentSlide = parseInt(slider.getAttribute('data-current-slide'));

	if (slide === "prev") {
		if (currentSlide === 0) {
			currentSlide = slides.length - 1;
		} else {
			currentSlide--;
		}
	} else if (slide === "next") {
		if (currentSlide === slides.length - 1) {
			currentSlide = 0;
		} else {
			currentSlide++;
		}
	} else {
		for (var i = 0; i < slides.length; i++) {
			if (slide === slides[i].getAttribute('data-model')) {
				currentSlide = i;
			}
		}
	}

	for (var i = 0; i < nav.length; i++) {
		nav[i].classList.remove("active");
		if (nav[i].getAttribute('data-value') === slides[currentSlide].getAttribute("data-model")) {
			nav[i].classList.add("active");
		}
	}

	slider.setAttribute("data-current-slide", currentSlide);
	slides[0].style.marginLeft = slides[0].getBoundingClientRect().width * -currentSlide + "px";
}
